/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://dwaknws5jbaaddvz65c7p6t4qu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:727b70ea-c70e-4402-b156-ff2cfcad2f15",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_SWr8E1m5R",
    "aws_user_pools_web_client_id": "3j29vr9vhhl9fajou7la54im05",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "smartport1fd30e95c68846669d7fec4ba60d518e113947-gbmon",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
